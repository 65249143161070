export const getDimensions = bounds => {
  let base = 24
  // return [base, base]
  // Is width larger than height?
  return bounds.width === Math.max(bounds.width, bounds.height)
    ? [base, ~~(base * (bounds.height / bounds.width))]
    : [~~(base * (bounds.width / bounds.height)), base]
}

export const make = ({ WIDTH, HEIGHT }) => {
  const C = 0.35
  const C2 = C * C
  const DAMPENING = 0.8

  const MAX_H = 2
  const MIN_H = -2
  const MAX_V = 5
  const MIN_V = -5

  function remap(low1, high1, low2, high2, value) {
    return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)
  }

  function heightToColor(height) {
    return ~~remap(MIN_H, MAX_H, 0, 255, height)
  }

  function clamp(low, high, value) {
    return Math.max(low, Math.min(high, value))
  }

  let data = new Array(WIDTH * HEIGHT * 4).fill()

  function draw(heights) {
    heights.forEach(function(h, i) {
      let color = heightToColor(h)
      let d = i * 4
      data[d] = color
      data[d + 1] = color
      data[d + 2] = color
      data[d + 3] = 255
    })
  }

  // SET UP SIMULATION
  let heights = new Array(WIDTH * HEIGHT)
    .fill()
    .map(() => Math.random() * 0.2 - 0.1)

  let velocities = new Array(WIDTH * HEIGHT).fill().map(() => 0)

  let read = (x, y) => {
    return heights[((HEIGHT + y) % HEIGHT) * WIDTH + ((WIDTH + x) % WIDTH)]
  }
  let write = (x, y, value) => {
    let i = x + y * WIDTH
    heights[i] = clamp(MIN_H, MAX_H, value)
  }

  let heightAdjust = 0

  function step(heights) {
    let avgHeight = 0
    for (let i = 0; i < heights.length; i += 1) {
      let x = i % WIDTH
      let y = ~~(i / WIDTH)
      let h = heights[i]
      let hN = read(x, y + 1)
      let hS = read(x, y - 1)
      let hW = read(x - 1, y)
      let hE = read(x + 1, y)
      let newV = ((C2 * (hN + hS + hW + hE - 4 * h)) / h) * h
      /* eslint-disable-next-line no-self-compare */
      if (newV !== newV) {
        heights[i] += 0.0000000001
        newV = velocities[i]
      }
      velocities[i] += newV
      velocities[i] *= DAMPENING
      velocities[i] = clamp(MIN_V, MAX_V, velocities[i])
      let newH = clamp(MIN_H, MAX_H, heights[i] + velocities[i] - heightAdjust)
      write(x, y, newH)
      avgHeight += newH
    }
    avgHeight /= heights.length * 2

    heightAdjust = avgHeight
  }

  // displaces some fluid to surrounding cells
  let displace = (x, y) => {
    let i = x + y * WIDTH

    let oldHeight = heights[i]
    let newHeight = MIN_H * 0.75
    heights[i] = newHeight
    let heightD = oldHeight - newHeight
    let quarterHeightD = heightD * 0.25

    heights[
      ((WIDTH + x + 1) % WIDTH) + ((HEIGHT + y) % HEIGHT) * WIDTH
    ] += quarterHeightD
    heights[
      ((WIDTH + x - 1) % WIDTH) + ((HEIGHT + y) % HEIGHT) * WIDTH
    ] += quarterHeightD
    heights[
      ((WIDTH + x) % WIDTH) + ((HEIGHT + y + 1) % HEIGHT) * WIDTH
    ] += quarterHeightD
    heights[
      ((WIDTH + x) % WIDTH) + ((HEIGHT + y - 1) % HEIGHT) * WIDTH
    ] += quarterHeightD

    // Maybe? or just 0;
    velocities[i] = velocities[i] * -1
  }

  function makeRaindrop() {
    let x = ~~(Math.random() * WIDTH)
    let y = ~~(Math.random() * HEIGHT)
    // This should be at least 2. 1 frame is not enough to make a ripple.
    let remaining = 2 + ~~(Math.random() * 6)
    let strength = 0.2 + Math.random() * 0.8
    return {
      update() {
        if (remaining <= 0) return
        displace(x, y, strength)
        remaining -= 1
        return remaining
      },
    }
  }

  let raindropCooldownTimer = 0
  let raindrop = makeRaindrop()

  let mousePositions = []

  let timeoutId = null

  // RUN
  function frame() {
    let frameDelay = 60

    // UPDATE RAINDROPS
    if (raindrop != null) {
      let remaining = raindrop.update()
      if (remaining <= 0) {
        raindrop = null
      }
    }
    raindropCooldownTimer -= 1
    if (raindropCooldownTimer <= 0) {
      raindrop = makeRaindrop()
      let ms = Math.random() * 1000 + 2000
      raindropCooldownTimer = ms / frameDelay
    }

    // DISPLACE WATER UNDER MOUSE
    mousePositions.forEach(function(p) {
      displace(p[0], p[1])
    })
    mousePositions = []

    // ADVANCE SIMULATION
    step(heights)
    // DRAW HEIGHTS TO CANVAS
    draw(heights)

    timeoutId = setTimeout(requestAnimationFrame, frameDelay, frame)
  }

  frame()

  return {
    getData() {
      return data
    },
    WIDTH,
    HEIGHT,
    updateMouse(x, y) {
      mousePositions.push([
        // Clamp the mouse to the canvas bounds
        Math.max(0, Math.min(WIDTH - 1, x)),
        Math.max(0, Math.min(HEIGHT - 1, y)),
      ])
    },
    destroy() {
      clearTimeout(timeoutId)
    },
  }
}
