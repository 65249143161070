import React from "react"

// TODO: export classes to use for container and slides

let modeBreakpoint = 768

let baseColumnW = 75
let baseGutterW = 40

export const sizes = {
  mobile: {
    ratio_16x9: { aspect: [4, 3], columns: 10 },
    ratio_1x1: { aspect: [1, 1], columns: 10 },
    ratio_4x3: { aspect: [4, 3], columns: 10 },
    ratio_3x4: { aspect: [3, 4], columns: 10 },
    ratio_2x3: { aspect: [2, 3], columns: 10 }, // portaits
  },
  tablet: {
    ratio_16x9: { aspect: [16, 9], columns: 6 },
    ratio_1x1: { aspect: [1, 1], columns: 4 },
    ratio_4x3: { aspect: [4, 3], columns: 4 },
    ratio_3x4: { aspect: [3, 4], columns: 4 },
    ratio_2x3: { aspect: [2, 3], columns: 4 }, // portraits
  },
}

export default function useItemsLayout(nodeRef, aspectRatios) {
  let [containerBounds, setContainerBounds] = React.useState(makeEmptyBounds())

  let mode = containerBounds.width >= modeBreakpoint ? "tablet" : "mobile"

  React.useEffect(() => {
    let handler = () => {
      let el = nodeRef.current
      if (el != null) {
        setContainerBounds(el.getBoundingClientRect())
      }
    }
    handler()
    // TODO: debounce/throttle?
    window.addEventListener("resize", handler)
    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [nodeRef, setContainerBounds])

  // Automatically set the height of the container to fit the children
  React.useEffect(() => {
    let el = nodeRef.current
    if (el != null) {
      el.style.height =
        Array.from(el.children).reduce(
          (max, node) => Math.max(max, node.offsetHeight),
          0
        ) + "px"
    }
  }, [containerBounds, nodeRef])

  let columnPx = (containerBounds.width * (baseGutterW / baseColumnW)) / 12

  let gutterPx = baseGutterW * (columnPx / baseColumnW)

  let offsets = [...aspectRatios, "ratio_1x1"].reduce(
    (acc, aspectRatio, i) => {
      let gutterW = baseGutterW * (columnPx / baseGutterW)
      let size = sizes[mode][aspectRatio]
      let width = columnPx * size.columns + (size.columns - 1) * gutterPx
      return {
        offsets: [...acc.offsets, acc.left],
        left: acc.left + width + gutterW,
      }
    },
    { offsets: [], left: 0 }
  ).offsets

  return { columnPx, gutterPx, offsets, mode }
}

function makeEmptyBounds() {
  return {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    right: 0,
    bottom: 0,
  }
}
